/* Iphone678se18 */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

p {
  margin: 0;
}

.clip-contents {
  overflow: hidden;
}

.flex-col-hstart-vstart {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-col-hcenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-hcenter {
  justify-content: center;
}

.iphone-678-se18 {
  position: relative;
  background: linear-gradient(
      0deg,
      rgba(229, 237, 245, 1),
      rgba(229, 237, 245, 1)
    ),
    url(https://firebasestorage.googleapis.com/v0/b/unify-bc2ad.appspot.com/o/1kmdg6vays-0%3A1247?alt=media&token=6a3b9827-bc09-46e5-8643-a331cccec6f2),
    linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  background-size: 100% 100%;
  width: 375px;
  height: 667px;
}

.group-9105 {
  position: absolute;
  top: 0px;
  /* none: 0px; */
  height: 631px;
  width: 100%;
}

.iphone-xnavigation-bar-default-copy {
  height: 70px;
  margin-bottom: 42px;
  width: 100%;
}

.txt-923 {
  font-size: 29px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  color: rgba(0, 57, 103, 1);
  word-wrap: break-word;
  margin-bottom: 47px;
}

.marriott-_hotels-_logo-141 {
  width: 116px;
  height: 90px;
  margin-bottom: 19px;
}

.txt-295 {
  font-size: 17px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  line-height: 135%;
  color: rgba(97, 113, 129, 1);
  text-align: center;
  word-wrap: break-word;
  margin-bottom: 58px;
}

.group-19008 {
  width: 160.46px;
  height: 160.46px;
  margin-bottom: 47px;
}

.group-18773 {
  padding: 25px 109px 24px 110px;
  box-sizing: border-box;
  background: url(https://firebasestorage.googleapis.com/v0/b/unify-bc2ad.appspot.com/o/1kmdg6vays-0%3A1308?alt=media&token=3c5a138a-2b17-4be0-a015-8e6250db5f3f);
  background-size: 100% 100%;
  height: 50px;
  width: fit-content;
}

.txt-005 {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: rgba(33, 150, 243, 1);
  text-align: center;
  word-wrap: break-word;
}

.image-1 {
  position: absolute;
  top: 32px;
  left: 92px;
  width: 190px;
  height: 20px;
}
