body {
    background-color: var(--white) !important;
    font-family: "Poppins", sans-serif;
}

a {
    color: #2095f3 !important;
}

.section-spacing {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.section-space {
    padding-top: 15px;
    padding-bottom: 15px;
}

.text-gray {
    color: var(--darkgray);
}

.text-green {
    color: var(--green);
}

.location span {
    font-size: 16px;
}

/* .readyto-check-sec .logo {
    min-width: 250px;
    margin: 0 0 15px 0;
} charmi */

.form-group {
    margin-bottom: 24px !important;
}

/* .checkin-app-form {
    margin: 0 0 20px 0;
} */
/* .readyto-check-sec .logo img {
    width: 250px;
}charmi */

.form-control {
    color: var(--darkblue) !important;
}

.reservation-sec ul.reservation-list {
    padding: 0;
}

.readyto-check-title {
    margin: 0 0 5px 0;
}

.text-blue {
    color: var(--darkblue);
}

.main-title {
    font-size: 21px;
    font-weight: 500;
}

.reservation-details {
    margin: 10px 0 0 0;
}

.reservation-sec {
    margin: 0 0 15px 0;
}

.foundreservation .reservation-sec {
    margin: 0 0 0 0;
}

.document-sacn {
    margin: 0 0 20px 0;
}

.reservation-sec .reservation-list {
    list-style: none;
    margin: 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

ul.selfverification-list {
    margin-bottom: 0;
}

.reservation-sec .reservation-list li {
    font-size: 16px;
    font-weight: 500;
}

input.form-control,
textarea.form-control {
    /*-webkit-box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important;
    box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important;
    border-color: transparent !important;
    background-color: #e5edf5 !important;*/
    min-height: 45px !important;
    /*55,charmi(23-02-2023)*/
    border-radius: 10px !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    border: 1px solid #bec5d1;
}

.form-control {
    font-weight: 500 !important;
}

.form-control::placeholder {
    color: #97acc7 !important;
    font-weight: 400 !important;
}

.form-control:focus {
    /* -webkit-box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    box-shadow: none !important;
    outline: none !important;
    border: 1px solid #bec5d1 !important;
}

.form-or {
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    /* border: 1px solid #fff; */
    color: #22317c;
    line-height: 1.5;
    background-color: #f2f2f2;
    border: 2px solid #ebecf0;
}

.form-or p {
    margin: 0;
    padding: 4px;
    font-size: 13px;
    text-align: center;
}

.btn-loader {
    border: 3px solid var(--blue);
    /* border: 3px solid var(--white); */
    /* Light grey */
    border-top: 3px solid var(--white);
    /* Blue */
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
}

.btn-loader.sm {
    width: 16px;
    height: 16px;
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.btn {
    background-color: #22317c !important;
    width: 100% !important;
    color: var(--white) !important;
    line-height: 1.4 !important;
    border-radius: 10px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding: 15px 20px !important;
    -webkit-box-shadow: #ccdff4 -6px -6px 13px -7px;
    box-shadow: #ccdff4 -6px -6px 13px -7px;
    border: none !important;
}

.btn-sm-txt {
    font-size: 16px;
}

.btn.disabled,
.btn:disabled {
    color: #7e97b8 !important;
    opacity: 1 !important;
}

.text-primary {
    color: #2196f3 !important;
}

.btn.focus,
.btn:focus {
    outline: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.rs-picker-date .rs-picker-toggle input,
.rs-picker-daterange .rs-picker-toggle input {
    padding: 10px 20px;
}

.rs-picker-toggle-placeholder {
    color: #97acc7 !important;
    font-size: 14px;
    line-height: 1.5;
    /*2.5,charmi(23-02-2023)*/
}

.rs-picker-toggle.rs-picker-toggle-active.rs-picker-toggle-placeholder {
    color: #c7b397 !important;
    font-size: 1rem;
}

.rs-picker {
    border-color: transparent !important;
    /* background-color: #e5edf5 !important; */
    height: calc(2.5em + 7px) !important;
    /*0.75rem + 3px,charmi(23-02-2023)*/
    border-radius: 10px !important;
}

.rs-picker:hover {
    border-color: 1px solid #ced4da;
}

.rs-picker .rs-btn,
.rs-input {
    cursor: text;
    height: calc(2.5em + 10px) !important;
    /*0.75rem + 8px,charmi(23-02-2023)*/
    padding: 10px 20px !important;
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    border: 1px solid #bec5d1 !important;
    box-shadow: none !important;
    background-color: var(--lightgray-100) !important;
}

.form-group-sm .rs-input,
.form-group-sm .rs-picker .rs-btn {
    padding: 5px 8px;
    min-height: 30px;
}

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
    padding: 6px 0 6px 0;
}

.rs-picker-menu {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.rs-picker-menu {
    position: absolute;
    text-align: left;
    z-index: 1050;
    border-radius: 6px;
    background-color: #fff;
    overflow: auto;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: auto;
    max-width: calc(100vw - 55.5%);
    box-shadow: #ccdff4 6px 5px 12px 0px;
    -webkit-box-shadow: #ccdff4 6px 5px 12px 0px;
}

.rs-picker-date-menu {
    max-width: unset;
}

.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
    padding-right: 44px;
}

.rs-picker-default .rs-picker-toggle,
.rs-input {
    text-align: left;
}

.rs-picker-toggle,
.rs-input {
    width: 100%;
}

.rs-picker-toggle-read-only {
    opacity: 0;
    pointer-events: none;
}

.rs-picker-toggle-textbox {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: none;
    color: var(--darkblue) !important;
    /* border-color: transparent !important; */
    /* background-color: #e5edf5 !important; */
    border-radius: 10px !important;
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    font-size: 1rem;
    line-height: 2.5;
    font-weight: 400;
    /* border: 1px solid #BEC5D1; */
}

.rs-picker-toggle-textbox::-webkit-input-placeholder {
    color: #97acc7 !important;
    font-size: 1rem;
}

.rs-picker-toggle-textbox::-moz-placeholder {
    color: #97acc7 !important;
    font-size: 1rem;
}

.rs-picker-toggle-textbox:-ms-input-placeholder {
    color: #97acc7 !important;
    font-size: 1rem;
}

.rs-picker-toggle-textbox::-ms-input-placeholder {
    color: #97acc7 !important;
    font-size: 1rem;
}

.rs-picker-toggle-textbox::placeholder {
    color: #97acc7 !important;
    font-size: 1rem;
}

.rs-picker-toggle-value {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: var(--darkblue) !important;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5 !important;
    /* 2.1 => charmi(27-02-2023)*/
}

.rs-picker-toggle-clean {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 17px;
    right: 40px;
    height: 20px;
    font-size: 12px;
    background: inherit;
    color: #007bff;
    stroke: #007bff;
    -webkit-transition: 0.2s color linear;
    -o-transition: 0.2s color linear;
    transition: 0.2s color linear;
    cursor: pointer;
}

.rs-btn-close .rs-icon {
    vertical-align: bottom;
}

.rs-calendar {
    padding-top: 12px;
    position: relative;
    min-height: 330px;
    overflow: hidden;
}

.rs-picker-menu .rs-calendar {
    min-width: 246px;
    display: block;
    margin: 0 auto;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
    margin: 0 auto;
    padding-left: 12px;
    padding-right: 12px;
}

.rs-picker-menu .rs-calendar-header {
    width: 100%;
    text-align: center;
}

/* start modal-center */
.rs-modal-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.rs-modal {
    margin: 0 auto !important;
    max-height: calc(100% - 10px);
}
/* end modal-center */
.rs-modal-body {
    position: relative;
    max-height: 100% !important;
}

.rs-modal-xl .rs-modal-body {
    height: 100%;
    max-height: 100% !important;
}

.rs-modal-xl .rs-modal-body.has-footer {
    max-height: calc(100% - 80px) !important;
}

.rs-modal-xl .rs-modal-header.diff-modal-title ~ .rs-modal-body {
    height: calc(100% - 35px) !important;
    max-height: 100% !important;
}

.rs-modal-body .table-responsive.project-table,
.rs-modal-body .right-sidebar-content {
    margin-top: 20px;
}

.rs-modal-body .card-content .table-responsive.project-table,
.rs-modal-body .right-sidebar-content .table-responsive.project-table {
    margin: 0;
}

.rs-modal-body .table-responsive.project-table td:first-child {
    padding: 10px;
}

.rs-modal-body .table-responsive.project-table tr th {
    padding: 10px;
}

.rs-modal-backdrop.rs-anim-in {
    opacity: 1;
}

.rs-modal-backdrop.rs-anim-fade {
    opacity: 0;
    -webkit-transition: opacity .3s ease-in;
    transition: opacity .3s ease-in;
}
.rs-anim-fade.rs-anim-in {
    opacity: 1;
    pointer-events: unset;
}
.rs-modal-backdrop {
    background-color: rgb(0 0 0 / 56%);
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1049;
}
.rs-anim-fade {
    opacity: 0;
    pointer-events: none;
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear;
}

.rs-modal-wrapper {
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
}

.rs-modal-sm, .rs-modal-xs {
    max-width: calc(100% - 10px);
}
.rs-modal-sm {
    width: 600px;
}
.rs-modal {
    display: none;
    margin: 30px auto 0;
    outline: 0;
    overflow: visible;
    position: relative;
    width: auto;
    z-index: 1050;
}
.rs-anim-bounce-in {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-name: g;
    animation-name: g;
    -webkit-animation-timing-function: cubic-bezier(.68,-.55,.27,1.55);
    animation-timing-function: cubic-bezier(.68,-.55,.27,1.55);
}
.rs-modal-content {
    background-color: #fff;
    /* background-color: var(--rs-bg-overlay); */
    border: none;
    border: var(--rs-modal-border,none);
    border-radius: 6px;
    -webkit-box-shadow: 0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06);
    box-shadow: 0 4px 4px rgba(0,0,0,.12), 0 0 10px rgba(0,0,0,.06);
    -webkit-box-shadow: var(--rs-modal-shadow);
    box-shadow: var(--rs-modal-shadow);
    outline: 0;
    padding: 20px;
    position: relative;
}


.rs-calendar-header:before,
.rs-calendar-header:after {
    content: " ";
    display: table;
}

.rs-calendar-header-month-toolbar {
    display: inline-block;
}

.rs-calendar-header-time-toolbar {
    display: inline-block;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-month-toolbar {
    display: block;
    text-align: center;
    width: 100%;
}

.rs-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.4;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    cursor: pointer;
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    outline: none;
}

.rs-btn-subtle {
    color: #007bff !important;
    background-color: #ffff !important;
    border: none;
}

.rs-btn-xs {
    font-size: 16px;
    line-height: 1.4;
    padding: 2px 8px;
}

.rs-btn-icon.rs-btn-xs {
    padding: 4px;
    line-height: 12px;
}

.rs-btn-subtle.rs-btn-icon {
    border: 1px solid #97acc7;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-backward {
    float: left;
}

.rs-btn-icon.rs-btn-xs > .rs-icon {
    font-size: 12px;
}

.rs-btn-icon > .rs-icon {
    vertical-align: bottom;
}

svg:not(:root) {
    overflow: hidden;
}

.rs-ripple-pond {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
}

.rs-ripple {
    position: absolute;
    display: block;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
}

.rs-calendar-header-title {
    margin: 0 4px;
    background: transparent !important;
    color: #007bff !important;
    font-weight: 600;
    font-size: 16px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-forward {
    float: right;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-month-toolbar:before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-month-toolbar:after {
    content: " ";
    display: table;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
    .rs-calendar-header-month-toolbar:after {
    clear: both;
}

.rs-calendar-header:after {
    clear: both;
}

.rs-calendar-view {
    padding: 4px 12px 12px;
}

.rs-calendar-table {
    display: table;
    table-layout: fixed;
}

.rs-calendar-table-row {
    display: table-row;
}

.rs-calendar-table-cell {
    display: table-cell;
    width: 1%;
    padding: 6px;
    text-align: center;
    vertical-align: middle;
}

.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month {
    opacity: 0.4;
}

.rs-calendar-table-row .rs-calendar-table-cell:first-child,
.rs-calendar-table-row .rs-calendar-table-cell:last-child {
    color: #007bff;
}

.rs-calendar-table-cell-content {
    font-size: 14px;
    line-height: 1.4;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
    color: #007bff;
    font-size: 14px;
    line-height: 1.6;
    padding-top: 2px;
    padding-bottom: 2px;
    font-weight: 600;
    border: 1px solid transparent;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
    background: none;
    cursor: auto;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    padding-left: 0;
    padding-right: 0;
    display: inline-block;
}

.rs-picker-menu
    .rs-calendar
    .rs-calendar-table-header-row
    .rs-calendar-table-cell-content {
    height: 26px;
    padding-top: 0;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    color: #fff;
    background-color: #007bff;
    -webkit-box-shadow: none;
    box-shadow: none;
}
    
.rs-calendar-table-cell-disabled, .rs-calendar-month-dropdown-cell.disabled {
    opacity: 0.5;
    user-select: none;
}

.rs-picker-menu
    .rs-calendar
    .rs-calendar-table-cell:hover
    .rs-calendar-table-cell-content {
    background-color: #007bff;
    color: #ffff;
}

.rs-calendar-table-row.rs-calendar-table-header-row
    .rs-calendar-table-cell:hover
    .rs-calendar-table-cell-content {
    background: transparent;
    color: #007bff;
}

.rs-picker-menu
    .rs-calendar
    .rs-calendar-table-cell:hover.rs-calendar-table-cell-selected
    .rs-calendar-table-cell-content {
    border: 1px solid #007bff;
}

.rs-btn-link {
    color: #007bff;
    background-color: transparent;
    border: none;
}

.rs-picker-toolbar-right {
    float: right;
}

.rs-btn-primary {
    color: #fff;
    background-color: #97acc7;
    border: none;
}

.rs-calendar-month-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    border-top: 1px solid #97acc7;
    margin-top: 0;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
    z-index: 1;
}

.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
    display: block;
}

.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
    animation: 0.3s linear slideDown;
}

.rs-calendar-month-dropdown-scroll {
    height: 230px;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
    height: 285px;
}

.rs-calendar-month-dropdown-row {
    position: relative;
    padding-left: 52px;
    padding-right: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
    border-bottom: 1px dotted #97acc7;
}

.rs-calendar-month-dropdown-year {
    position: absolute;
    top: calc(50% - 0.8em);
    left: 12px;
    font-weight: 600;
    color: #007bff;
}

.rs-calendar-month-dropdown-list {
    display: block;
}

.rs-calendar-month-dropdown-cell {
    display: inline-block;
    width: calc((100% - 1px * 12) / 6);
    margin: 1px;
    text-align: center;
    vertical-align: middle;
}

.rs-calendar-month-dropdown-cell-content {
    display: inline-block;
    font-size: 14px;
    line-height: 1.4;
    padding: 5px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid transparent;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
    width: 30px;
    height: 30px;
}

.rs-calendar-month-dropdown-list:before,
.rs-calendar-month-dropdown-list:after {
    content: " ";
    display: table;
}

.rs-calendar-month-dropdown-year-active {
    color: var(--darkblue);
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #007bff;
    color: #fff;
}

.rs-calendar-month-dropdown-cell-active
    .rs-calendar-month-dropdown-cell-content {
    color: #fff;
    background-color: #007bff;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.rs-picker-menu
    .rs-calendar
    .rs-calendar-month-dropdown-cell-active
    .rs-calendar-month-dropdown-cell-content:hover {
    border: 1px solid #97acc7;
}

.rs-picker-daterange-menu.rs-picker-menu {
    width: auto;
}

.rs-picker-daterange-header {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border-bottom: 1px solid #97acc7;
}

.rs-picker-daterange-calendar-group {
    min-width: 492px;
}

.rs-picker-daterange-menu .rs-calendar {
    display: inline-block;
    height: 278px;
    padding-bottom: 12px;
    border-bottom: 1px solid #97acc7;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
    border-right: 1px solid #97acc7;
}

.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
    position: relative;
}

.rs-calendar-table-cell-selected:before,
.rs-calendar-table-cell-in-range:before {
    content: "";
    display: block;
    width: 100%;
    margin-top: 4px;
    height: 34px;
    position: absolute;
    z-index: 0;
    top: 0;
}

.rs-calendar-table-cell-in-range:before {
    background-color: #000;
    color: #007bff;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
    z-index: 1;
    position: relative;
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
    text-align: center;
}

.rs-calendar-time-dropdown {
    display: none;
    position: absolute;
    top: 44px;
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    background-color: #fff;
    color: #007bff;
    overflow: hidden;
    z-index: 1;
}

.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
    display: block;
}

.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
    -webkit-animation: 0.3s linear slideDown;
    animation: 0.3s linear slideDown;
}

@-webkit-keyframes slideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }

    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.rs-calendar-time-dropdown-row {
    display: table;
    width: 100%;
}

.rs-calendar-time-dropdown-column {
    display: table-cell;
    width: 1%;
}

.rs-calendar-time-dropdown-column-title {
    width: 100%;
    background-color: #e5edf5;
    color: #007bff;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    padding-top: 2px;
    padding-bottom: 2px;
}

.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.rs-calendar-time-dropdown-column > ul {
    height: 250px;
    overflow-y: auto;
    padding-bottom: 30px;
}

.rs-calendar-time-dropdown-cell {
    font-size: 14px;
    line-height: 20px;
    color: #007bff;
    padding: 5px;
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
}

.rs-calendar-time-dropdown-cell:focus,
.rs-calendar-time-dropdown-cell:focus-within,
.rs-calendar-time-dropdown-cell:focus-visible,
.rs-calendar-time-dropdown-cell:hover {
    background-color: #007bff;
    color: #fff !important;
    text-decoration: none !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
    background-color: #007bff;
    color: #fff !important;
}

.rs-calendar-time-dropdown-cell:hover.rs-calendar-time-dropdown-cell-active {
    border-color: #97acc7;
}

.rs-row::before,
.rs-row::after {
    content: " ";
    display: table;
}

.rs-col {
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 5px;
    padding-right: 5px;
}

.rs-input {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    border: 1px solid transparent;
    border-radius: 4px;
    white-space: nowrap;
    vertical-align: middle;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 1.4;
    overflow: hidden;
    text-decoration: none;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    cursor: text;
    background: var(--white);
    color: var(--darkblue);
    -webkit-transition: color 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
}

.rs-input:focus-visible {
    outline: none !important;
}

.rs-picker-toggle-textbox:focus-visible {
    outline: none !important;
}

.rs-picker-toggle-caret {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    margin-left: 2px;
    position: absolute;
    top: 17px;
    right: 12px;
    height: 20px;
    font-size: 16px;
    color: var(--darkblue);
    padding-left: 5px;
    /* background: #e5edf5; */
}

.rs-picker-toggle-textbox::-webkit-input-placeholder {
    color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::-moz-placeholder {
    color: var(--placeholder-color);
}

.rs-picker-toggle-textbox:-ms-input-placeholder {
    color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::-ms-input-placeholder {
    color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::placeholder {
    color: var(--placeholder-color);
}

.checkbox,
.radio {
    display: block;
    position: relative;
    padding-left: 30px !important;
    margin-bottom: 40px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 16px;
    line-height: 1.3;
}

.checkbox input,
.radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox .checkmark,
.radio .checkmark,
.checkmark-manual {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    /* background-color: #e5edf5; */
    border: 1px solid #7e97b8;
    z-index: 1;
    /* -webkit-box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important;
    box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
}

.checkbox .checkmark:after,
.radio .checkmark:after,
.checkmark-manual:after {
    content: "";
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    width: 5px;
    bottom: 0px;
    margin: auto;
    height: 11px;
    border: solid var(--darkblue);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox .checkmark:after,
.checkmark-manual:after {
    bottom: 2px;
}

.id-verification-sec .card {
    margin: 20px 20px 20px 20px;
}

.checkbox input:checked ~ .checkmark:after,
.radio input:checked ~ .checkmark:after,
.checkbox input:checked ~ .checkmark-manual:after {
    display: block;
}

/* .btn:hover {
    -webkit-box-shadow: #ccdff4 6px 5px 12px 0px, inset #ccdff4 -6px -6px 13px -7px;
    box-shadow: #ccdff4 6px 5px 12px 0px, inset #ccdff4 -6px -6px 13px -7px;
} */

.btn {
    color: var(--white) !important;
    /* -webkit-box-shadow: #ccdff4 6px 5px 12px 0px, inset #ccdff4 -6px -6px 13px -7px; */
    box-shadow: none;
}

.card {
    /* -webkit-box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important;
    box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    border: 1px solid #c5e2ff !important;
    background-color: #f3f9ff !important;
    border-radius: 10px !important;
    margin: 0 0 20px 0 !important;
}

.card-body {
    padding: 1.25rem 3rem !important;
}

.sec-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--darkblue);
    line-height: 1.4;
}

.issuedid-sec {
    margin: 15px 0 0px 0;
}

.reservation-sec .reservation-list li p {
    margin-bottom: 0;
}

.reservation-detail-title .sec-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
}

.reservation-details ul.reservation-detail-list {
    padding: 0;
    list-style: none;
    margin: 0 auto;
}

.reservation-details span {
    color: var(--darkblue);
}

.reservation-details .reservation-name {
    font-weight: 600;
}

.fw-600 {
    font-weight: 600;
}

.fw-500 {
    font-weight: 500;
}

.location {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1;
}

.foundreservation .card-body {
    padding: 1.25rem !important;
}

.stay-apply p {
    margin-bottom: 15px !important;
}

.location-icon {
    margin: auto;
}

.location-icon img {
    position: relative;
    top: auto;
    bottom: 2px;
    height: 18px;
}

.btn-icon {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.back-btn {
    border-radius: 0px !important;
    background: #7e97b8 !important;
    color: var(--white) !important;
}

.back-btn:hover {
    color: var(--white) !important;
}

.btn .right-arrow-icone {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-filter: brightness(0) invert(1);
    filter: brightness(0) invert(1);
}

/* .right-arrow-icone img {
    vertical-align: revert;
} */

.right-arrow-icone {
    display: block;
}

.btn.back-btn .right-arrow-icone img {
    position: relative;
    top: auto;
    bottom: 2px;
    height: 13px;
}

.select-document .card .card-body span {
    text-align: center !important;
    font-size: 12px;
    font-weight: 700;
}

.select-document .card .card-body {
    padding: 1rem !important;
    text-align: center;
}

hr {
    border-top: 2px solid rgb(173 191 212) !important;
}

.document-image {
    position: relative;
    display: block;
    height: 50px;
    overflow: hidden;
    margin-bottom: 18px;
}

.document-image img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.select-document.self-verification-info .card .card-body {
    padding: 2rem !important;
}

.self-verification-info .document-image img {
    max-width: 60px;
    padding: 0 0 100px 0;
}

.fw-700 {
    font-weight: 700;
}

.selfverification-info .selfverification-list li::marker {
    color: #6f7e8d;
    font-size: 14px;
}

.selfverification-info .selfverification-list li p {
    margin-bottom: 0px;
}

.detailaboutyou-title p {
    font-size: 18px !important;
    font-weight: 500;
    margin-bottom: 0;
}

.hr-line hr {
    border: none !important;
    -webkit-box-shadow: 0 -2px 2px 1px #ffff;
    box-shadow: 0 -2px 2px 1px #ffff;
    margin: 0 0 20px 0;
}

.intl-tel-input {
    position: relative;
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin-left: 135px !important;
    display: inline-block;
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
}

.intl-tel-input.allow-dropdown .selected-flag {
    width: 80px;
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    border: 1px solid #bec5d1 !important;
    background-color: var(--white) !important;
    height: 45px !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
}

.intl-tel-input .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    -webkit-box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
    background-color: white;
    border: 1px solid #ccc;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.intl-tel-input .country-list .country.highlight {
    background-color: rgba(0, 0, 0, 0.05);
}

.back-btn-sec {
    background-color: #fff !important;
    width: 100% !important;
    color: #97acc7 !important;
    line-height: 1.4 !important;
    border-radius: 10px !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 15px 20px !important;
    box-shadow: #ccdff4 -6px -6px 13px -7px;
    border: none !important;
    border-radius: 0px !important;
    background: #22317c !important;
    color: #ffff !important;
}

.id-verification-sec .select-document .card {
    border: transparent !important;
    background-color: #fff !important;
    box-shadow: -1px 2px 17px 5px rgb(0 0 0 / 10%);
}

.self-verification-sec .select-document.self-verification-info .card,
.stayinformation-sec .standardcheckin-sec .card {
    background-color: var(--white) !important;
    box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 10%);
    border: transparent !important;
}

/* .days-suites-logo{
    margin: 0 0 15px 0 !important;
} */
.back-btn-sec a {
    color: #ffff !important;
    text-decoration: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.back-btn-sec a .right-arrow-icone img {
    -webkit-filter: brightness(0) invert(1) !important;
    filter: brightness(0) invert(1) !important;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}

.time-info {
    font-size: 25px;
    font-weight: 600;
}

.time-info p {
    margin-bottom: 0;
}

.lightblue {
    color: #2196f3;
}

.rs-picker-select-menu-item {
    padding: 8px 20px;
    display: block;
    font-weight: 500;
    color: var(--darkblue);
    cursor: pointer;
    font-size: 13px;
}

.rs-picker-none {
    padding: 5px 20px;
    display: block;
    font-weight: 500;
    color: var(--darkblue);
    cursor: not-allowed;
}

.rs-picker-select-menu-item:hover {
    color: #2196f3;
}

.stayinformation-sec .rs-picker-toggle-value {
    color: var(--darkblue) !important;
    text-transform: none;
}

.input-group-text-lg {
    position: absolute;
    top: 28px;
    left: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 14px;
    margin-bottom: 0;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
}

ul.plusminus-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style-type: none;
}

ul.plusminus-list li {
    padding: 0 10px 0 0;
}

.btn.plus-minus-icon {
    line-height: 34px !important;
    padding: 0 !important;
    border-radius: 5px !important;
    color: #2196f3 !important;
    height: 34px;
    /*36px => charmi(27-02-2023)*/
    width: 34px !important;
    /*36px => charmi(27-02-2023)*/
    font-size: 24px !important;
    display: flex;
    align-items: center;
}

.btn.plus-minus-icon span {
    width: 100%;
}

.input-group-text-lg.input-group-text-left {
    top: 9px;
    /*15px => charmi(27-02-2023)*/
    left: 15px;
    /*5px => charmi(27-02-2023)*/
}

.adult-children .form-control {
    padding: 10px 40px !important;
}

.input-group-text-right {
    left: auto !important;
    right: 0px;
    /*-9px => charmi(27-02-2023)*/
    top: 0;
}

.adult-children .form-control {
    text-transform: none !important;
    font-weight: 500 !important;
    background-color: #fff;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
    color: #2196f3;
}

.information-parking .form-control {
    text-transform: none !important;
    font-weight: 400 !important;
}

.document-image.visacard-img img {
    max-width: 350px;
    padding: 0 0 200px 0;
}

.creditard-desc {
    color: #ee7377;
}

.scan-card img {
    position: relative;
    top: auto;
    bottom: 2px;
    height: 18px;
}

.terms-conditions-title .main-title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
.terms-condition .rs-modal-body {
    top: 0px !important;
    position: relative;
}

.terms-condition .rs-modal-header .rs-modal-header-close.rs-btn-close {
    width: 28px;
    height: 28px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 9px;
    background: var(--btn-danger-transparent);
    border-radius: 50%;
    transition: all 0.5s;

}

.terms-condition .rs-btn-close .rs-icon {
    background-color: transparent;
    /* background-color: var(--btn-danger-transparent); */
    color: red;
    font-weight: 600;
}

.terms-condition .rs-modal-header .rs-modal-header-close {
    position: absolute;
    right: 16px;
    top: 10px;
    font-size: 12px;
    line-height: 1.66666667;
    color: var(--dark);
    width: 20px;
    padding: 0 4px;
    z-index: 4;
}


.understand-sec .checkbox,
.radio {
    padding-left: 30px;
}

.electronic-signature .card-body {
    padding: 1.25rem !important;
}

.electronicsign-area {
    /* max-height: 200px;
    overflow: auto; */
    min-height: 250px;
}

.document-image.successfulcheck-img img {
    max-width: 225px;
    padding: 0 0 200px 0;
}

/* .days-suites-logo .document-image img{
    max-width: 270px;
    padding: 0 0 160px 0;
} */
.document-image.scan-img img {
    max-width: 450px;
    padding: 0 0 600px 0;
}

.sacn-link .retake-link,
.sacn-link .submit-link {
    font-size: 18px;
    font-weight: 500;
}

.sacn-link .retake-link {
    color: #f22728 !important;
}

/* .document-image.dayssuites-logo img {
    max-width: 170px;
    padding: 0 0 100px 0;
} */
.dayssuites-logo {
    position: relative;
    display: block;
    height: 120px;
    /*charmi(21-02-2023)*/
    overflow: hidden;
    margin-bottom: 20px;
    /*charmi(21-02-2023)*/
}

.dayssuites-logo img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.validunexpired-sec {
    margin: 0 0 20px 0;
    color: var(--darkgray);
}

.document-image.scan-img {
    margin-bottom: 10px;
}

.scan-img {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
}

.scan-img img {
    position: relative;
    display: block;
    max-height: calc(100vh - 170px);
    width: 100%;
    margin: auto;
}

.selfverification-info {
    margin: 0 0 30px 0;
}

p:last-child {
    margin-bottom: 0;
}

.desc-data {
    margin: 0 0 20px 0;
}

.set-please {
    margin-top: 2rem;
}

.submit-btn {
    font-weight: 600;
    color: #2095f3;
}

.retake-btn {
    font-weight: 600;
    color: #f32121;
    cursor: pointer;
}

.submit-btn-sec {
    margin: 0 0 20px 0;
}

p:last-child {
    margin-bottom: 0;
}

.creditard-desc.desc-data {
    margin: 20px 0 20px 0;
}

.info-data {
    margin: 0 0 20px 0;
}

.idverification-imgs {
    position: relative;
    display: block;
    height: 50px;
    overflow: hidden;
    margin-bottom: 3px;
}

.idverification-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.id-verification-sec .back-btn-sec {
    margin-bottom: 30px;
}

.hotelcharges-desc.desc-data {
    margin: 0 0 30px 0;
    font-size: 15px;
}

.scancard-desc.desc-data {
    margin: 30px 0 30px 0;
    font-weight: 500;
}

.stay-term-box-data {
    font-weight: 500;
}

.stay-apply {
    font-size: 15px;
    line-height: 1.5;
}

.hotel-stayterm {
    color: var(--darkgray);
}

.electronicsign-title {
    font-weight: 600;
    font-size: 16px;
}

.electronicsign-desc {
    color: var(--darkgray);
    font-size: 12px;
}

.electronic-signature {
    position: relative;
    display: block;
    /* height: 90px;charmi(21-02-2023) */
    /* margin-left: 5%;charmi(21-02-2023) */
    /* width: 85%;charmi(21-02-2023) */
    text-align: center;
    margin: auto;
    /*charmi(21-02-2023)*/

    /* overflow: hidden; */
}

.electronic-signature img {
    /* position: absolute; */
    display: block;
    /* top: 20; */
    /* bottom: 10; */
    /* left: 10; */
    /* right: 10; */
    margin: auto;
    /* width: 95%; */
    height: 95%;
}

.successfulcheck-img {
    position: relative;
    display: block;
    height: 200px;
    overflow: hidden;
}

.successfulcheck-img img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.label-form {
    text-transform: uppercase;
    font-weight: 600;
    color: var(--darkblue);
    font-size: 16px;
}

/* .stayinformation-sec .submit-btn-sec.addremovebtn .btn:hover {
    color: #003967 !important;
} */

.r-hotelcharges-left {
    font-weight: 500;
}

.r-hotelcharges-right {
    font-weight: 600;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    display: table-cell !important;
    vertical-align: baseline !important;
    padding-left: 5px !important;
    font-weight: 500 !important;
    color: var(--darkblue) !important;
}

.intl-tel-input .flag-container .arrow {
    display: inline-block !important;
    vertical-align: middle;
}

.intl-tel-input .selected-flag {
    line-height: 1.5;
}

.issuedid-sec {
    font-size: 16px;
    color: var(--darkgray);
}

.validunexpired-sec {
    font-size: 16px;
    /* margin-top: 2rem; */
}

.standardcheckin-title {
    font-size: 16px;
}

.review-hotelcharges span {
    font-size: 14px;
}

.sacn-link {
    margin: 20px 0 0px 0;
    cursor: pointer;
}

/* .document-image.logo img {
    max-width: 210px;
    padding: 0 0 50px 0;
}charmi */

.redirect-section {
    position: sticky;
    top: 0;
    z-index: 2;
}

.stayinformation-sec .submit-btn-sec.addremovebtn .btn,
.termsstay-sec .submit-btn-sec a {
    color: var(--white) !important;
    background-color: #1eb05a !important;
}

.fw-700 {
    font-weight: 700 !important;
}

::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    scrollbar-width: thin;
    scrollbar-color: var(--gray-400) var(--gray-100);
}

::-webkit-scrollbar-track {
    background-color: var(--gray-100);
    -webkit-border-radius: 50px;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background: var(--gray-400);
}

/* .btn:hover {
    color: #003967!important;
}
.btn{
    color: #2196f3!important;  
} */

/* Error Style Start */
.validate-input.error .label-form-control {
    color: #d30808;
}

.validate-input.error .form-control.rs-auto-complete,
.validate-input.error .form-control.rs-picker,
.validate-input.error textarea.form-control,
.validate-input.error input.form-control,
.validate-input.error input.form-control::placeholder {
    color: #d30808 !important;
    border-color: #d30808 !important;
}

.validate-input.error .form-control.rs-auto-complete:hover,
.validate-input.error .form-control.rs-auto-complete:focus,
.validate-input.error .form-control.rs-auto-complete:focus-within,
.validate-input.error .form-control.rs-picker:hover,
.validate-input.error .form-control.rs-picker:focus,
.validate-input.error .form-control.rs-picker:focus-within,
.validate-input.error textarea.form-control:hover,
.validate-input.error textarea.form-control:focus,
.validate-input.error textarea.form-control:focus-within,
.validate-input.error input.form-control:hover,
.validate-input.error input.form-control:focus,
.validate-input.error input.form-control:focus-within {
    /* box-shadow: 0 0 3px #D30808 !important; */
    border-color: #d30808 !important;
}

.error404 {
    height: calc(100vh - 170px);
}

.error-content {
    text-align: center;
}

.error-content .mini-text {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 0;
}

.error-content .error-img {
    max-width: 500px;
}

.error-content .error-text {
    font-size: 18px;
    color: var(--dark-800);
    font-weight: 600;
}

.error .error {
    font-size: 13px;
    font-style: italic;
}

/* Error Style End */

/* WELCOME START */
.welcome-title h1 {
    color: var(--darkblue);
    font-size: 38px;
    margin-top: 30px;
}

.welcome-img-logo {
    position: relative;
    display: block;
    height: 100px;
    overflow: hidden;
    margin: 30px 0;
}

.welcome-img-logo img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.welcome-text {
    color: var(--darkgray);
    font-size: 20px;
    margin: 40px 0;
}

.welcome-img {
    margin: 40px 0;
    position: relative;
    display: block;
    height: 280px;
    overflow: hidden;
}

.welcome-img img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

/* WELCOME END */

/* NEW CHANGE START */

.extra-space {
    height: calc(60px + 20px);
}

.btn-primary-dark {
    color: #fff !important;
}

.sticky-btn {
    position: sticky;
    top: auto;
    left: 0;
    right: 0;
    bottom: 70px;
    margin: auto;
    width: 100%;
    display: block;
    z-index: 5;
    max-width: 100%;
}

.hotelcharges-sec .submit-btn-sec,
.welcome-section .submit-btn-sec,
.self-verification-sec .submit-btn-sec,
.id-verification-sec .submit-btn-sec,
.checkin-app-form .submit-btn-sec,
.staydetails-sec .submit-btn-sec {
    position: absolute;
    display: block;
    width: -webkit-fill-available;
    width: -moz-available;
    bottom: auto;
    margin: auto 20px;
}

.checkin-app-form .checkbox {
    color: var(--darkgray);
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    width: 120px !important;
}

textarea.form-control {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.rs-picker-menu .rs-picker-search-bar {
    position: relative;
    padding: 6px 12px 12px;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
    display: block;
    width: 100%;
    color: #003971;
    background-color: #e5edf5;
    background-image: none;
    border: 1px solid transparent;
    -webkit-transition: border-color ease-in-out 0.3s,
        background-color ease-in-out 0.3s;
    transition: border-color ease-in-out 0.3s, background-color ease-in-out 0.3s;
    border-radius: 4px;
    padding: 7px 11px;
    font-size: 14px;
    line-height: 1.42857143;
    min-width: 200px;
    padding-right: 36px;
    font-weight: 500;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
    position: absolute;
    width: 14px;
    color: var(--secondary);
    font-size: 14px;
    height: 20px;
    top: 14px;
    right: 24px;
}

input.rs-picker-search-bar-input:focus-within {
    outline: none;
}

.btn-cam-icon {
    position: relative;
    top: -1px;
}

.visacard-img {
    position: relative;
    display: block;
    height: 210px;
    overflow: hidden;
}

.visacard-img img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.vehicleimageslist {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: 10px;
}

.preview-img {
    /* height: 140px;
    width: 140px; */
    position: relative;
    display: block;
    border: 2px solid #fff;
    border-radius: 7px;
    column-count: 1;
    overflow: hidden;
    margin: 10px auto 30px;
}

.close-vehicle-image {
    position: absolute;
    right: 4px;
    top: 4px;
    background: #e5edf5;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    cursor: pointer;
    box-shadow: #ccdff4 0px 0px 8px 0px;
    z-index: 1;
}

.close-vehicle-image svg {
    width: 22px;
    height: 22px;
}

.preview-img img {
    position: relative;
    height: 100%;
    width: 100%;
    display: block;
    /* top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto !important; */
}

.sample-img {
    column-count: 1;
    border: 2px solid #fff;
    margin: 10px auto;
    border-radius: 7px;
    overflow: hidden;
}

.sample-img img {
    width: 100%;
    height: 100%;
}

.textarea-input input {
    border-radius: 10px;
    font-weight: 500;
    text-align: left;
}

/* NEW CHANGE END */

.left-arrow-icone img {
    filter: brightness(0) invert(1);
    -webkit-filter: brightness(0) invert(1);
}

.stayinformation-sec .submit-btn-sec .btn.plus-minus-icon {
    background-color: #f3f9ff !important;
    border: 1px solid #d4e7fb !important;
}

.stayinformation-sec .set-carbtn .submit-btn-sec.addremovebtn .btn.submit-btn,
.stayinformation-sec .set-clearbtn a,
.submit-btn-sec.set-clearbtn .submit-btn {
    border: 1px solid rgba(112, 112, 112, 0.2) !important;
    color: #757575 !important;
    background-color: #f5f7f9 !important;
}

.hotelcharges-sec .review-hotelcharges .hr-line hr {
    border: 1px solid rgba(126, 151, 184, 0.25) !important;
    box-shadow: none;
}

.creditard-desc.desc-data p {
    text-align: justify;
    font-weight: 700;
}

.termsstay-sec .electronic-signature-pad .card {
    background-color: #fff !important;
    box-shadow: 0px 0px 21px 4px rgb(0 0 0 / 10%);
    border: 1px solid #e1e1e1 !important;
}

.reservation-details-sec .card-body {
    padding: 1.25rem 1.2rem !important;
}

.set-vehicalbtn.btn {
    background-color: #f5f7f9 !important;
    color: #22317c !important;
    border: 1px solid #22317cba !important;
}

.inn-suites-desc.desc-data p {
    color: #22317c;
}

.background-scre {
    background-color: #f3f9fe !important;
}

.set-padding-doc {
    margin: 1rem 0;
    padding: 1rem 0;
}

.readyto-check-sec .checkbox-outline-primary span a {
    color: #f10 !important;
}

.set-row {
    margin: 2rem 0;
}

.detailsaboutyou-sec .hr-line hr {
    box-shadow: none !important;
    border: 1px solid rgba(126, 151, 184, 0.25) !important;
}

.set-btn-detailspg {
    padding: 0 20px;
    margin-top: 1rem;
}

.set-pchecking {
    padding: 0 1rem;
}

.set-or-verif {
    margin: 0 !important;
}

.set-data-id {
    justify-content: center;
    align-items: center;
}

.stayinformation-sec .hr-line hr {
    box-shadow: none !important;
    border: 1px solid rgba(126, 151, 184, 0.25) !important;
}

.document-sacn.set-scan {
    margin-bottom: 2rem;
}

.set-reteckbtn label {
    border-radius: 10px;
    justify-content: center;
    padding: 15px 40px;
    border: 1px solid rgba(112, 112, 112, 0.2) !important;
    color: #757575 !important;
    background-color: #f5f7f9 !important;
}

.set-reteckbtn label .retake-btn {
    color: #757575 !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.setbtn-submit span {
    color: var(--white) !important;
    background-color: #1eb05a !important;
    padding: 15px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.paymentcard-submit-btn {
    background-color: #1eb05a !important;
}

.setbtn-vehiclephoto div {
    color: var(--white) !important;
    background-color: #22317c !important;
    width: 100%;
    padding: 15px 33px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.retake-sec .btn-loader,
.selfphoto-retakesubmit .btn-loader {
    position: absolute;
    top: 33px;
    right: 21px;
}

.set-picuter {
    padding: 0;
}

.stayinformation-sec .rs-picker-toggle-value span {
    font-size: 15px;
}

.termsstay-sec .main-title {
    font-weight: 600;
}

.termsstay-sec .termsconditions-desc {
    font-size: 14px;
    color: #7e97b8;
}

.readyto-check-sec .main-title {
    font-weight: 700;
}

/* srushti */
/* 17-01 */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.bottom-nav {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-top: 1px solid rgb(230, 230, 230);
}

.bn-tab {
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spacing {
    margin-top: 5%;
}

.welcome-msg p {
    margin: 0 !important;
}

/* .text-white1 {
    color: hwb(0 88% 8%);
} */

/* // srushti */

/* home page css*/

.set-data-id-guest {
    justify-content: left;
}

.card-home {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    height: 130px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    cursor: pointer;
}

/* .spacing1 {
    margin-top: 20%;
} */

/* .select-document-home .card-home .card-home-body span {
    text-align: center !important;
    font-size: 12px;
    font-weight: 500;

} */

.select-document-home .card-home .card-home-body {
    /* padding: 2rem !important; */
    text-align: center;
}

.dnd-imgs {
    position: relative;
    display: block;
    height: 75px;
    overflow: hidden;
    margin-bottom: 3px;
}

.dnd-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.box-space {
    padding: 10px;
}

.background-scre-home {
    /* background-color: #F3F9FE !important; */
    /* background: linear-gradient(#3905f7, #9198e5);
    background: linear-gradient(45deg, red, blue); */
    background-image: linear-gradient(to left, #1973d1, #010280);
    padding: 0px 0 40px 48px;
}

.background-scre-blue {
    background-image: url("../images/blue.png") !important;
    /* height: 850px; */
    height: 74vh;
}

/* .boxx-border-wifi {
    border-left: 2px solid rgb(224, 218, 218);
    text-align: center;
    border: none;
    font-size: 12px;
}

.wifi-imgs-main {
    position: relative;
    display: block;
    height: 60px;
    overflow: hidden;
    margin-bottom: 3px;

} */

/* .wifi-imgs-main img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
} */

.box {
    width: 85%;
    padding: 7px;
    background: var(--white);
    margin: -45px auto 10px;
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    border: 3px solid rgb(42, 91, 197);
    cursor: pointer;
}

.boxx-border {
    border-right: 1px solid rgb(235, 232, 232);
    /* height: 90px; */
    padding: 5px;
    text-align: center;
}

.boxx-border:last-child {
    border-right: none;
}

.wifi-imgs {
    position: relative;
    display: block;
    height: 50px;
    overflow: hidden;
    margin-bottom: 3px;
}

.wifi-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.label-name {
    font-size: 12px;
    font-weight: 600;
}

.weatherbox {
    width: 101%;
    height: 100px;
    padding: 7px;
    background: var(--white);
    margin-top: 10px;
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    background-image: linear-gradient(to left, rgb(169, 222, 255), #32a3ff);
}

.customer-name {
    font-weight: 500;
}

.customer-hello {
    font-weight: 400;
}

.customer-checkout {
    font-weight: 300;
    font-size: 12px;
}

.tap-to-locate-imgs {
    position: relative;
    display: block;
    height: 40px;
    overflow: hidden;
}

.tap-to-locate {
    width: 20%;
    height: 60px;
    /* padding: 7px; */
    background: var(--darkblue-100);
    /* margin: -230px 370px 100px; */
    border-radius: 5px !important;
    box-shadow: var(--lightblue-100) 3px 3px 17px 3px;
    text-align: right;
    position: absolute;
    top: -155px;
    right: 60px;
}

/* .tap-to-locate-label{
    color: var(--white);
    font-size: 5px;
    position: relative;
    
} */

.room-no {
    position: absolute;
    bottom: 0;
    right: 20px;
    top: 0;
    font-size: 32px;
    font-weight: 600;
}

.tap-to-locate-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: auto;
    height: 35px;
}

/* .tap-to-locate-block{
    position: absolute;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    border: 1px solid rgb(212, 213, 216);
    font-size: 10px;

} */

/* // srushti */
/* wifi-page css */

.text-light-blue {
    color: var(--lightblue);
}

.title-foundsec p {
    font-size: 13px;
    color: #6d6d6d;
    font-weight: 500;
}

.wifi-pass-box {
    width: 100%;
    height: 180px;
    padding: 7px;
    background: var(--white);
    border-radius: 5px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    border: 1px solid rgb(212, 213, 216);
}

.room-no-box {
    width: 23%;
    background: #fff;
    border-radius: 9px !important;
    background-color: var(--darkblue);
    border: 1px solid rgb(212, 213, 216);
    position: relative;
    left: 39%;
    font-size: 40px;
    text-align: center;
}

.network {
    font-size: 13px;
    color: var(--darkblue);
    font-weight: 500;
}

.wifi-box-scroll {
    overflow: auto;
    white-space: nowrap;
}

.wifi-device-box {
    width: 100%;
    height: 180px;
    padding: 4px;
    background: var(--white);
    border-radius: 5px !important;
    /* overflow: auto; */
    /* overscroll-behavior-inline: contain; */
    /* white-space: nowrap; */
}

/* device wifi css */

.device-card {
    /* -webkit-box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important;
    box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    border: 1px solid rgb(212, 213, 216);
    background-color: var(--white) !important;
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    cursor: pointer;
    /* width: 90%;  */
}

.box-space-wifi {
    display: inline-block;
    padding: 10px;
}

.device-card-body {
    padding: 1.25rem 1rem !important;
    width: 100px;
}

.device-document .device-card .device-card-body {
    /* padding: 2rem !important; */
    text-align: center;
    margin: 0 10px;
}

.device-images {
    position: relative;
    display: block;
    height: 40px;
    overflow: hidden;
}

.device-images img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.device-name p {
    font-size: 10px;
    padding: 5px;
    font-weight: 600;
}

.support {
    font-size: 10px;
    font-weight: 600;
}

.contact-box {
    height: 28px;
    left: 33%;
    width: 35%;
    background: var(--white);
    position: relative;
    border-radius: 5px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.call-images {
    position: relative;
    display: block;
    height: 15px;
    overflow: hidden;
    top: 7px;
    left: 7px;
}

.call-images img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.call-number {
    color: var(--lightblue);
    font-size: 15px;
    padding: 5px;
    position: relative;
    right: 25px;
}

.ios-model {
    width: 25%;
}

.wifi-modal-step {
    font-size: 12px;
    font-weight: 500;
}

.ios-paragraph hr {
    border-top: 1px solid #ccc !important;
}

.ios-tip-paragraph {
    font-size: 10px;
    text-align: center;
}

.wifi-header {
    background-color: var(--darkblue);
    color: var(--white);
    height: 40px;
    text-align: center;
    padding: 10px;
    position: relative;
}

.rs-btn-close .rs-icon {
    background-color: var(--white);
    vertical-align: middle;
}

.rs-modal-header {
    padding: 0;
}

.rs-modal-header .rs-modal-header-close {
    position: absolute;
    color: var(--darkblue);
    background-color: var(--white);
    right: 15px;
    top: 9px;
    z-index: 1;
    border-radius: 10px 10px 10px 10px;
}

.icon-close {
    position: absolute;
}

/* .rs-modal-content {
    padding: 0 !important;
    border-radius: 10px !important;
    overflow: hidden !important;
} */

/* 
stay page css
srushti
01-02-23 */

.card-home {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    height: 130px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

/* .spacing1 {
    margin-top: 20%;
} */

/* .select-document-home .card-home .card-home-body span {
    text-align: center !important;
    font-size: 12px;
    font-weight: 500;

} */

.card-stay {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 15px !important;
    height: 75px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    cursor: pointer !important;
}

.card-stay-imgs {
    position: relative;
    display: block;
    height: 55px;
    overflow: hidden;
}

.card-stay-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    /* width: 100%; */
    height: 100%;
}

.label-name-stay {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

/* 
srushti
2-2-23
contactus design */

.card-contact {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    height: 150px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.contact-imgs {
    position: relative;
    display: block;
    height: 70px;
}

.contact-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.label-name-contact {
    font-size: 17px;
    font-weight: 500;
    color: var(--darkblue);
    text-align: center;
}

.contactus-main-label {
    font-size: 27px;
    color: var(--darkblue);
}

/* 
srushti
3-2-23
first popup design */

.first-popup-title {
    font-size: 16px;
    color: var(--darkblue);
    font-weight: 700;
}

.first-popup-label {
    font-size: 11px;
    font-weight: 600;
    color: var(--gray);
}

.first-pop-logo {
    position: relative;
    display: block;
    height: 60px;
}

.first-pop-logo img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 150px;
    margin: auto;
    width: auto;
    height: 100%;
    cursor: pointer;
}

.tap-to-see {
    font-size: 12px;
    color: var(--red);
}

.btn-first-popup {
    background-color: var(--btn-lightblue) !important;
    width: 100% !important;
    color: var(--white) !important;
    border-radius: 8px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 20px !important;
    box-shadow: #ccdff4 -6px -6px 13px -7px;
    border: none !important;
}

.first-pop-up-or {
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    line-height: 1.5;
    background-color: #f2f2f2;
    border: 2px solid #ebecf0;
    font-size: 15px;
}

.first-pop-up-or p {
    margin: 0;
    padding: 4px;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    color: var(--darkblue);
}

.first-popup-input {
    min-height: 50px !important;
    border-radius: 10px !important;
    padding: 10px 20px !important;
    font-size: 1px !important;
    border: 1px solid #bec5d1;
}

.border-bottom-dark-800 {
    border-bottom: 3px solid var(--dark-800) !important;
}
/* 
srushti
7-2-23
image popup design */

.image-modal-capture img {
    height: 60px;
    cursor: pointer;
    /*charmi(14-03-2023)*/
    /* left: 50px; */
    /*charmi(14-03-2023)*/
    /* padding: 0 120px; */
    /*charmi(14-03-2023)*/
}

/* 
srushti
7-2-23
rate popup design */

.rate-modal-sub-title {
    color: var(--gray);
    font-weight: 600;
    font-size: 12px;
}

.rate-box-content p {
    font-size: 11px;
    text-align: center;
    border-bottom: 1px solid var(--lightgray);
    padding: 10px;
    color: var(--darkgray);
}

.rate-box {
    border: 1px solid var(--lightgray);
    border-radius: 10px;
    height: 108px;
}

.star-image img {
    height: 25px;
    margin: 15px;
    /* margin-left: 10px; */
}

.suggestion-title {
    font-size: 14px;
    text-align: center;
}

.suggestion-text textarea {
    border-radius: 10px;
    background-color: var(--lightgray-100);
    border: none;
    height: 100px;
    width: 100%;
    padding: 10px;
    font-size: 11px;
    color: var(--darkgray-100);
    font-weight: 500;
    outline: none;
}

/* 
srushti
8-2-23
your request design */

.request-box {
    /* width: 100%; */
    background: var(--white);
    margin-top: 10px;
    border-radius: 7px !important;
    border: 2px solid #d9d9d9;
    color: var(--black);
    cursor: pointer;
}

.request-create span {
    font-weight: 600;
    font-size: 15px;
}

.request-create p {
    font-weight: 600;
    font-size: 10px;
    color: #bcbcbc;
}

.request-create img {
    height: 17px;
    /* display: block; */
}

.ticket-no {
    background: #f0f8ff;
    border: 1px solid #6bb5ef;
    border-radius: 5px !important;
    font-size: 12px;
    height: 30px;
    color: #0180e5;
    padding: 5px;
    text-align: center;
    font-weight: 500;
}

.rate-service {
    font-size: 11px;
    color: #bcbcbc;
}

.hr {
    border-bottom: 1px solid #d9d9d9;
}

.request-item {
    font-size: 12px;
    font-weight: 600;
    color: #565656;
}

.with-req {
    display: flex;
    margin: 13px;
}

.withdraw {
    border: 1px solid #777777;
    border-radius: 5px !important;
    font-size: 12px;
    height: 30px;
    color: #777777;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    display: inline-block;
    width: 100%;
    margin: 0 10px;
}

.track {
    border: 1px solid #0180e5;
    border-radius: 5px !important;
    font-size: 12px;
    /* margin-left: 200px; */
    height: 30px;
    color: #0180e5;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    width: 100%;
    display: inline-block;
    margin: 0 10px;
}

.reopen {
    border: 1px solid var(--green);
    border-radius: 5px !important;
    font-size: 12px;
    height: 30px;
    color: var(--green);
    padding: 5px;
    text-align: center;
    font-weight: 500;
    /* width: 100%; */
    /* display: inline-block; */
    margin: 10px 10px 10px 10px;
}

.star-image-request img {
    height: 15px;
    margin: 5px;
}

.edit-icon-request {
    position: sticky;
    bottom: 0;
    cursor: pointer;
    margin: 85%;
}

/* 
srushti
9-2-23
footer design */

.footer {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    height: 60px;
    /* Height of the footer */
    background-image: linear-gradient(to left, #1973d1, #010280);
    border-radius: 12px 12px 0 0;
    overflow: hidden;
}

.footer a {
    padding: 5px 0 0 0;
    text-align: center;
    display: block;
    border-top: 3px solid transparent;
    transition: all 0.5s;
    text-decoration: none;
}

.footer a.active {
    border-top: 3px solid white;
}

.footer-icon img {
    height: 22px;
    margin: auto;
    display: block;
}

.footer-icon span {
    color: var(--white);
    font-size: 10px;
}

.footer-icon .col {
    padding: 0 9px;
}

/* 
srushti
9-2-23
header design */

.header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: var(--header-color);
}

.header-icon {
    margin: 8px 0;
}

.header-icon a {
    display: inline-block;
}

.header-icon img {
    height: 45px;
    padding: 5px;
}

/* 
srushti
9-2-23
guest request design */
.product-add-box {
    width: 100%;
    background: var(--white);
    border-radius: 10px !important;
    border: 1px solid #d9d9d9;
    height: 45px;
    color: var(--black);
    cursor: pointer;
    /* position: absolute; */
}

.guest-request-maintenance-lable {
    font-weight: 600;
    font-size: 15px;
}

/* .guest-request-lable {
    font-weight: 600;
} */

.guest-request-table {
    width: 100%;
    background: var(--white);
    border-radius: 5px !important;
    border: 1px solid #d9d9d9;
}

.guest-request-table tr {
    border: none !important;
}

.req-item-list {
    color: var(--black);
    font-size: 14px;
    font-weight: 600;
    border-bottom: none;
}

.req-grid-height {
    max-height: calc(100vh - 50px);
    overflow: auto;
}

.guest-request-add-item {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
    width: 100%;
    height: 45px;
    font-weight: 600;
    color: var(--darkblue);
}

.add-box {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 5px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* width: 100%; */
    height: 26px;
    font-weight: 600;
    color: var(--darkblue);
}

.remove-box {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 5px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* width: 100%; */
    height: 26px;
    font-weight: 600;
    color: var(--darkblue);
    position: absolute;
    margin-left: 82%;
}

/* 
srushti
9-2-23
maintenance page design */

.mainten-req-sentence {
    font-size: 14px;
    font-weight: 600;
    color: var(--darkgray-100);
}

.mainten-req-sentence-desc {
    font-size: 10px;
    font-weight: 500;
    color: var(--lightgray-200);
}

.mainten-img img {
    /* width: 100%;
    height: 100%; */
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden !important;
    box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 0px;
    cursor: pointer;
}

ul.mainten-list {
    list-style-position: outside;
    font-size: 10px;
    color: var(--lightgray-200);
}

.reopen-mainten {
    border: 1px solid #0180e5;
    border-radius: 5px !important;
    font-size: 12px;
    height: 30px;
    color: #0180e5;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    margin: 8px;
}

.resolve-text {
    color: var(--green);
}

.capture-image-mainten img {
    display: block;
    position: absolute;
    width: 50px;
    /* cursor: pointer;
    z-index: 0; */
}

.capture-input {
    opacity: 0;
    width: 50px;
    height: 50px;
    display: block;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

/* maintenance page 
for multiple image slider  */
.rs-carousel-slider-item {
    /* background-color:#b7b7bd; */
    background-color: var(--white);
    float: left;
    height: 100%;
    width: 100%;
}

.rs-carousel-label-wrapper {
    margin: 3px;
    background-color: var(--lightgray);
    border-radius: 5px 5px 5px 5px;
    width: 10px;
    height: 10px;
}

.multiple-image-slider img {
    width: 100%;
    height: 100%;
    overflow: hidden !important;
}

/* .footer a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
   */

/* jatin start*/
.content {
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
    /* border-left: 5px solid #007bff; */
}

.subtext {
    font-size: 12px;
}

.maintext {
    font-weight: 520;
}

.line-1 {
    height: 2px;
    background: rgb(233, 230, 230);
    width: 100%;
}

.textcontainer {
    padding: 15px;
    display: grid;
}

.arrow-img {
    margin-top: 30px;
    /* margin-left: 460px; */
    /* margin-bottom: 12px; */
}

.content-boder-left {
    margin-top: 10px;
    /* margin-right: 20px; */
    margin-left: 1px;
    border-radius: 5px;
    width: 100%;
    background-color: var(--white);
    box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
    border-left: 5px solid #007bff;
}

.direction-img {
    margin-top: 10px;
    /* margin-left: 60px; */
}

.direction {
    font-size: 10px;
    font-weight: 500;
    /* margin-left: 52px; */
    color: #32a3ff;
}

.background-fullscre-img {
    background-image: url("../images/blue.png") !important;
    height: 800px;
}

/* amenitiesView */
.amenities-img {
    height: 65px;
    border-radius: 7px;
    width: 75px;
    padding: 1px;
    overflow: hidden !important;
    box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 0px;
}

.text-format {
    font-size: 13px;
}

.img-scroll {
    display: inline;
    overflow: auto;
    white-space: nowrap;
}

.text-bold {
    font-weight: bold;
}

.tvchannel-img {
    height: 45px;
    /* border-radius: 20px; */
    margin: 10px;
    width: 60px;
    box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 0px;
}

.tvchannel-header {
    background-color: var(--darkblue);
    color: var(--white);
    height: 35px;
    padding: 5px;
    /* width: 540px; */
    max-width: calc(100vh - 190px);
}

.maincontent {
    /* width: 100%; */
    height: 55px;
    background-color: var(--lightdarkgray);
}

.channelNoboder {
    border: 1px solid rgb(180, 175, 175);
    border-radius: 5px;
    margin-left: 15px;
    width: 70px;
    margin-top: 10px;
    /* padding: 10px; */
    text-align: center;
    /* position: relative; */
}

.tv-line {
    height: 2px;
    background: rgb(233, 230, 230);
    width: 95%;
    margin-left: 10px;
    font-weight: 500;
}

.tv-grid-height {
    max-height: calc(100vh - 220px);
    overflow: auto;
}

.tvchannel-img-scroll {
    display: inline;
    overflow: auto;
    white-space: nowrap;
}

.tvchannelbox {
    border: 1px solid #0180e5;
    border-radius: 10px !important;
    font-size: 12px;
    height: 80px;
    color: #0180e5;
    padding: 5px;
    text-align: center;
    font-weight: 500;
    margin: 10px 10px;
    width: 90px;
    display: inline;
    overflow: auto;
    white-space: nowrap;
}

.imgsize {
    height: 56px;
    width: 56px;
}

.icon-card {
    border: 1px solid rgb(212, 213, 216);
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.tvchannne-box-scroll {
    display: flex;
    overflow: auto;
    white-space: nowrap;
}

.channel-img {
    position: relative;
    /* display: block; */
    height: 50px;
    overflow: hidden;
}

.tvch-card-body {
    padding: 5px 20px 0px 20px !important;
    width: 100px;
    height: 85px;
}

.icon-card.active,
.icon-card:hover {
    background: var(--lightblue-100);
}

.icon-card.active img,
.icon-card:hover img {
    filter: brightness(3);
}

.icon-card.active .channel-name .icon-card:hover .channel-name {
    color: white;
}

.box-tvchannel {
    display: inline-block;
    padding: 10px;
}

.channel-name p {
    font-size: 10px;
    font-weight: 600;
}

.card-hover {
    text-align: center;
}

.hovercolor :hover {
    background-color: #228ee6;
    cursor: pointer;
    border-radius: 10px !important;
}

/* Check-out Popup model in HomeView.js */
.btn-yes-no {
    width: 80% !important;
    color: var(--black) !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 20px !important;
    /* box-shadow: #ccdff4 -6px -6px 13px -7px; */
    border: 1px solid gray !important;
    padding-left: 10px;
}

.btn-hover :hover {
    background-color: var(--btn-lightblue) !important;
    border-radius: 5px !important;
}

.checkout-img-box {
    border: 1px solid rgb(212, 213, 216);
    border-radius: 10px !important;
    height: 65px;
}

.checkout-img-box.active,
.checkout-img-box:hover {
    background: var(--lightblue-100);
}

.checkout-img-box.active img,
.checkout-img-box:hover img {
    filter: brightness(3);
}

.checkout-img-box.active .text-center,
.checkout-img-box:hover .text-center {
    color: white;
}

.text-left {
    text-align: left !important;
}

.checkout-img {
    height: 35px;
    margin-left: 12px;
    margin-right: 12px;
}

.chechout-box {
    display: flex;

    padding: 10px;
}

.chechout-box-space {
    /* padding: 10px; */
    margin-left: 65px;
}

.text-size {
    font-size: 10px;
    /* padding: 5px; */
    font-weight: 600;
}

.housekeeping-box {
    padding: 10px;
    /* padding-left: 30px; */
}

.housekeeping-img-box {
    border: 1px solid rgb(212, 213, 216);
    border-radius: 10px !important;
}

.housekeeping-img {
    height: 45px;
    width: 69px;
}

.housekeeping-hover :hover {
    background-color: #228ee6;
    cursor: pointer;
    border-radius: 10px !important;
}

.housekeeping-later-line {
    width: 90%;
}

.form-control-sm {
    min-height: 39px !important;
    font-size: 14px !important;
    width: 100%;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: var(--lightgray-100);
    outline: none;
}

.form-control-textarea {
    min-height: 100px !important;
    font-size: 14px !important;
    width: 100%;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;
    background-color: var(--lightgray-100);
    outline: none;
    margin-bottom: 0px;
}

.housekeeping-rate-modal-sub-title {
    color: var(--lightblue);
    font-weight: 600;
    font-size: 12px;
}

.custom-control-input:checked ~ .custom-control-label {
    background: var(--lightblue-100);
    color: #fff;
    border-radius: 10px !important;
}

.custom-control-input:checked ~ .custom-control-label .housekeeping-img {
    filter: brightness(2.5);
}

.custom-control-label::before,
.custom-control-label::after {
    display: none;
}

.form-control-checkout {
    min-height: 39px !important;
    font-size: 14px !important;
    width: 116%;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: var(--lightgray-100);
    outline: none;
}

/* 28-2-23 jatin */
/* task tag */
.task-tag-custom-control {
    background: var(--White);
    display: inline-flex;
    flex-wrap: wrap;
    padding: 13px 14px;
}

.task-tag-custom-control .custom-control-tag {
    display: flex;
    margin: 0 !important;
    min-height: auto;
    /* padding: 5px 5px; */
    margin: 0 20px 20px 0;
}

.task-tag-custom-control .custom-control-tag .custom-label {
    padding: 9px 16px;
    font-size: 12px;
    line-height: 1;
    border-radius: 1.5rem;
    background: var(--warning);
    display: block;
    font-weight: 500;
    cursor: pointer;
    margin: 0 1px;
    text-align: center;
    font-size: 15px;
}

.task-tag-custom-control .custom-control-tag .custom-label-rt {
    padding: 4px 14px;
    font-size: 12px;
    line-height: 1;
    border-radius: 5px;
    /* background: var(--warning); */
    display: block;
    font-weight: 500;
    cursor: pointer;
    margin: 0 1px;
    text-align: center;
    font-size: 8px;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* srushti for radio button in report problem page 20/3/2023 */
/* .custom-control-input1 { 
    position: absolute;
    z-index: 3;
    opacity: 1;
}
.custom-control-label1 {
    position: relative;
    margin-left: 20px;
    vertical-align: top;
} */

.content-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.extend-popup-body {
    font-size: 15px;
    color: var(--darkblue);
    font-weight: 700;
}

.body-fix-height {
    height: calc(100vh - 176px);
    overflow: auto;
    /*charmi(16-03-2023) height: calc(94vh - 128px);*/
}

/* .rs-modal-content-center{
    padding-top: 10px !important;
    border-radius: 10px !important;
    overflow: hidden !important;  
    margin-top: 120px;        
} */
/* jatin End */

/* charmi start */
.fix-height-scroll {
    max-height: calc(100vh - 130px);
    /* height: calc(102vh - 130px); */
    overflow: auto;
}

.inner-fixed-height{
    overflow-y: scroll;
    /* height: 100vh; */
    max-height: calc(100vh - 200px);
}

.bg-blue {
    background: #ecf7ff !important;
    margin: 40px 0;
    padding: 40px 0;
}

/* 
.background-img {
    background-image: url(http://192.168.2.2:9999/static/media/blue.7251512affbdde4fa659.png);
    height: 100vh;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.background-header-home {
    background-image: linear-gradient(to left, #1973D1, #010280);
    padding: 10px 40px 60px;
}

.home-header-left {
    display: inline-block;
    vertical-align: middle;
}

.tap-to-locate-block {
    background: var(--darkblue-100);
    box-shadow: 3px 3px 17px 3px var(--lightblue-100);
    border-radius: 5px;
    padding: 10px;
    vertical-align: middle;
    display: inline-block;
    float: right;
}

.tap-to-locate-block img {
    height: 30px;
    display: inline-block;
}

.header-roomno {
    display: inline-block;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    color: var(--white);
    vertical-align: middle;
    padding: 0 10px;
}

.tap-to-locate-label {
    color: var(--white) !important;
    background: #3465ca;
    padding: 3px 6px;
    font-size: 10px;
    border-radius: 4px;
    text-transform: capitalize;
    line-height: 1;
    display: block;
} */
/* charmi end */

/* srushti css (restructure)*/
/* 20/2/23 */

.background-img {
    /* change srushti 7-3-23 */
    background-image: url(http://192.168.2.2:9999/static/media/blue.7251512affbdde4fa659.png);
    height: 100vh;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-color: #f5f9ff;
    background-color: #fff;
}

.background-header-home {
    background-image: linear-gradient(to left, #1973d1, #010280);
    padding: 10px 40px 60px;
}

.home-header-left {
    display: inline-block;
    vertical-align: middle;
}

.tap-to-locate-block {
    background: var(--darkblue-100);
    box-shadow: 3px 3px 17px 3px var(--lightblue-100);
    border-radius: 5px;
    padding: 5px;
    vertical-align: middle;
    display: inline-block;
    float: right;
}

.tap-to-locate-block img {
    height: 30px;
    display: inline-block;
    cursor: pointer;
}

.header-roomno {
    display: inline-block;
    margin-bottom: 0;
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    color: var(--white);
    vertical-align: middle;
    padding: 0 10px;
    cursor: pointer;
}

.tap-to-locate-label {
    color: var(--white) !important;
    background: #3465ca;
    padding: 3px 6px;
    font-size: 10px;
    border-radius: 4px;
    text-transform: capitalize;
    line-height: 1;
    display: block;
}

.tap-to-locate-block a:hover {
    text-decoration: none;
    cursor: pointer;
}

/* 
srushti 
21-2-23 homepge(restructure)*/

/* header menu */
.header-menu {
    width: 85%;
    padding: 7px;
    background: var(--white);
    margin: -45px auto 10px;
    border-radius: 10px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    border: 3px solid rgb(42, 91, 197);
    cursor: pointer;
}

.header-menu a {
    text-decoration: none;
    color: var(--black) !important;
}

.home-menu-border {
    border-right: 1px solid rgb(235, 232, 232);
    /* height: 90px; */
    padding: 5px;
    text-align: center;
}

.home-menu-border:last-child {
    border-right: none;
}

.home-menu-imgs {
    position: relative;
    display: block;
    height: 50px;
    overflow: hidden;
    margin-bottom: 3px;
}

.home-menu-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

.home-menu-label-name {
    font-size: 12px;
    font-weight: 600;
}

/* 
srushti 
21-2-23 homepge(restructure)*/
/* body menu */
.home-body-menu {
    border: 1px solid var(--white) !important;
    background-color: var(--white) !important;
    border-radius: 10px !important;
    height: 130px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
    cursor: pointer;
}

.home-body-imgs {
    position: relative;
    display: block;
    height: 75px;
    overflow: hidden;
    margin-bottom: 3px;
}

.home-body-imgs img {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: 100%;
}

/* 
srushti 
21-2-23 homepge(restructure)*/
/* vehicle model */

.vehicle-model-title {
    font-size: 16px;
    color: var(--darkblue);
    font-weight: 700;
    /* text-transform: capitalize; */
}

.vehicle-model-camera-logo {
    position: relative;
    display: block;
    height: 60px;
}

.vehicle-model-camera-logo img {
    position: absolute;
    top: 0px;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 100%;
    cursor: pointer;
}

.tap-to-see-example-camera {
    font-size: 12px;
    color: var(--red);
}

.vehicle-model-btn {
    background-color: var(--btn-lightblue) !important;
    width: 100% !important;
    color: var(--white) !important;
    border-radius: 8px !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    padding: 10px 20px !important;
    box-shadow: #ccdff4 -6px -6px 13px -7px;
    border: none !important;
}

.vehicle-model-or {
    /* box-shadow: inset 5px 4px 10px -3px #5f9de77a, inset -6px -6px 7px -3px #ffffff !important; */
    width: 30px;
    height: 30px;
    margin: auto;
    border-radius: 50%;
    line-height: 1.5;
    background-color: #f2f2f2;
    border: 2px solid #ebecf0;
    font-size: 15px;
}

.vehicle-model-or p {
    margin: 0;
    padding: 4px;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    color: var(--darkblue);
}

.vehicle-model-label {
    font-size: 13px;
    font-weight: 500;
    color: var(--gray);
}

/* Bhautik 23-02-2023 12:39 PM */
.video-card {
    position: relative;
}

.video-card::before {
    content: "";
    position: absolute;
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 10px !important;
}

.font-weight-400 {
    font-weight: 400 !important;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.font-weight-600 {
    font-weight: 600 !important;
}
.font-weight-700 {
    font-weight: 700 !important;
}
.gap-8 {
	gap: 8px;
}
.gap-14 {
	gap: 14px;
}

.text-16 {
    font-size: 16px;
}

.text-18 {
    font-size: 18px !important;
}

.text-19 {
    font-size: 19px !important;
}

.text-20 {
    font-size: 20px !important;
}

.text-14 {
    font-size: 14px !important;       
}

.text-12 {
    font-size: 12px !important;
}

.text-justify {
    text-align: justify !important;
}

.card-content {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    /* background-color: rgba(255,255,255,0.5); */
    /* position: relative; */
    /* background-color: rgba(0,0,0,1); */
    /* background-color: black;     */
    /* mix-blend-mode: darken; */
}

/* .card-content::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    background: red;
    top: 0;
    left: 0;
    z-index: 1000;
} */

/* .card-content::after {
    content: '';
    position: absolute;
    width: 90%;
    height: 90%;
  }  */

.cursor-not-allowed {
    cursor: not-allowed !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.set-scancard {
    border-radius: 10px;
    justify-content: center;
    padding: 15px 40px;
    border: 1px solid rgba(112, 112, 112, 0.2) !important;
    color: #757575 !important;
    background-color: #f5f7f9 !important;
}

.setbtn-continue {
    color: var(--white) !important;
    background-color: #1eb05a !important;
    padding: 15px 40px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.text-on-camera {
    position: absolute;
    z-index: 1;
    color: white;
    /* margin:22px; */
}

.btn-on-camera {
    position: relative;
    bottom: 70px;
}

.btndiv-on-camera {
    position: relative;
    bottom: 70px;
}

/* srushti
guest requuets progressbar 
2-3-23 */

.wrapper {
    font-size: 14px;
}

.StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
}

.StepProgress::before {
    display: inline-block;
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
}

.StepProgress-item {
    position: relative;
    counter-increment: list;
}

.StepProgress-item:not(:last-child) {
    padding-bottom: 20px;
}

.StepProgress-item::before {
    display: inline-block;
    content: "";
    position: absolute;
    left: -30px;
    height: 100%;
    width: 10px;
}

.StepProgress-item::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: -35px;
    width: 11px;
    height: 11px;
    border: 2px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
}

.StepProgress-item.current.is-done::before {
    border-left: 2px solid var(--green-100);
}

.StepProgress-item.current.is-done::after {
    /* content: "✔"; */
    font-size: 8px;
    color: #fff;
    text-align: center;
    border: 2px solid var(--green-100);
    background-color: var(--green-100);
}

.StepProgress-item.current::before {
    border-left: 2px dashed var(--lightgray-300);
}

.StepProgress-item.current::after {
    /* content: counter(list); */
    width: 8px;
    height: 8px;
    /* top: -1px; */
    left: -33px;
    font-size: 10px;
    text-align: center;
    /* color: green; */
    border: 2px solid var(--lightgray-300);
    background-color: var(--lightgray-300);
}

.StepProgress strong {
    display: block;
    color: var(--darkgray-100);
    font-size: 14px;
}

.progress-detail {
    color: var(--black);
    font-size: 10px;
    font-weight: 500;
}

.request-item-progress {
    font-size: 17px;
    font-weight: 600;
    color: var(--darkgray-100);
}

.request-item-progress p {
    font-size: 11px;
    font-weight: 600;
    color: var(--lightgray-200);
}

.progress-date {
    font-size: 10px;
    color: var(--lightgray-200);
    font-weight: 500;
}

/* srushti 6-3-23 guest request close icon */
.close-icon {
    cursor: pointer;
}

/* srushti 7-3-23 notification  */

.notification-date-text span {
    color: var(--darkblue-200);
    font-weight: 600;
    font-size: 12px;
}
.notification-box {
    width: 100%;
    /* height: 73px; */
    padding: 7px;
    background: var(--white);
    border-radius: 5px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
}

.notification-img img {
    height: 50px;
    border-radius: 30px;
}

.notification-text span {
    font-size: 11px;
    font-weight: 600;
    margin-left: 9px;
}

.notification-text p {
    font-size: 12px;
    font-weight: 600;
    margin-left: 9px;
}

.notification p {
    font-size: 11px;
    font-weight: 500;
    color: #22317c;
}

.notification img {
    height: 11px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 600;
    margin: 2px 4px 0 0;
}

.notification-box-border {
    border-left: 1px solid rgb(235, 232, 232);
    /* height: 90px; */
}

.notification-box-border span {
    font-size: 11px;
    font-weight: 600;
}

.rs-input:focus {
    box-shadow: none !important;
}

.welcome-bold-text {
    font-weight: 600;
    font-size: 22px;
}

.btn-success {
    background-color: var(--success) !important;
}

.selfverification-img img {
    height: 94px;
}

.btndiv-on-camera-idverification {
    position: relative;
    bottom: 15%;
}

.margin-left-30 {
    margin-left: 30px !important;
}

.margin-bottom-25 {
    margin-bottom: 25px !important;
}

/* 13/3/2023 heli start */
.btn-radio-control {
    background: var(--white);
    display: inline-block;
    display: inline-flex;
    border-radius: 4px;
    border: 1px solid var(--input-border);
    padding: 3px 4px;
}

.btn-radio-control .custom-control {
    display: inline-block;
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto;

    position: relative;
    min-height: 1.2195rem;
    padding-left: 1.5rem;
}

.btn-radio-control .custom-control input:checked ~ .custom-control-label {
    background: var(--darkblue) !important;
    color: #fff;
    border-radius: 4px !important;
    /* background: var(--dark);
    color: var(--white-color); */
}

.border-radius-5 {
    border-radius: 5px !important;
}

.border-radius-10 {
    /* bhautik 14-07-2023 */
    border-radius: 10px !important;
}

.add-field-btn-paymentmethod {
    /* padding: 7px !important; */
    display: flex;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid var(--transparent);
    padding: 10px;
    min-width: 34px;
    min-height: 34px;
    border-radius: 4px;
    transition: all 0.25s;
    align-items: center;
    line-height: 1;
    height: 44px;
    width: 44px;
    text-decoration: none !important;
}

.btn-primary-light-paymentmethod {
    color: var(--white) !important;
    /* border: 1px solid var(--transparent) !important; */
    background: #22317c !important;
}

.btn-primary-light-paymentmethod:hover,
.btn-primary-light-paymentmethod:focus,
.btn-primary-light-paymentmethod:active {
    color: var(--white) !important;
    /* border: 1px solid var(--btn-primary) !important; */
    background-color: #22317c !important;
}

/* 13/3/2023 heli end */

/*Charmi(15-03-2023) Video/Canvas Start*/
/* .card-container-main-block {
    width: 100%;
    height: 100%;
} */

.video-container-block {
    width: initial;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    height: 400px;
}

.video-card-capture {
    position: relative;
    display: flex;
    align-items: center;
    max-width: none;
    width: 100%;
    /* height: 400px !important; */
    object-fit: cover;
}

.video-card-capture::before {
    content: "";
    position: relative;
}

.video-scanner-canvas-block {
    aspect-ratio: 3 / 4;
    position: absolute;
    /* position: relative; */
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    height: 205px;
}

.canvas-parent-block {
    /* display: flex; */
    /* align-items: center; */
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 0;
    mix-blend-mode: darken;
    /* justify-content: center; */
}

.canvas-block {
    aspect-ratio: 3 / 4;
    position: absolute;
    display: block;
    background: #fff;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 90%;
    height: 205px;
}

.borders-block {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.border-box {
    width: 95%;
    height: 225px;
    /* width: 300px;
    height: 150px; */
    position: absolute;
    display: block;
    border: 3px solid white;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.box-borders {
    position: absolute;
    display: block;
    width: 28px;
    height: 28px;
    background: transparent;
    border-style: solid;
    border-color: #fff;
    margin: auto;
}

.left-top {
    left: -5px;
    top: -5px;
    right: auto;
    bottom: auto;
    border-width: 3px 0 0 3px;
}

.right-top {
    right: -5px;
    top: -5px;
    left: auto;
    bottom: auto;
    border-width: 3px 3px 0 0;
}

.left-bottom {
    left: -5px;
    bottom: -5px;
    right: auto;
    top: auto;
    border-width: 0 0 3px 3px;
}

.right-bottom {
    right: -5px;
    bottom: -5px;
    left: auto;
    top: auto;
    border-width: 0 3px 3px 0;
}
.capture-btn {
    position: relative;
    bottom: 75px;
    top: auto;
    margin: auto;
    left: 0;
    right: 0;
}

.section-spacing-img img {
    height: 100%;
    width: 100%;
    /* object-fit: scale-down; */
}

.section-spacing-img {
    /* max-height: 400px;
    height: 100%; */
}

.instruction-block {
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    position: absolute;
    display: block;
    width: 100%;
    height: auto;
    top: 0;
    bottom: auto;
    margin: auto;
    color: #fff;
    line-height: 2;
}

.payment-container .border-box {
    height: 180px;
    width: 95%;
}

.payment-container .canvas-block,
.payment-container .video-scanner-canvas-block {
    height: 160px;
    width: 90%;
}

.payment-container .video-container-block,
.payment-container .video-card-capture {
    height: 350px;
}

.rs-modal-title span.formnamelbl-checkin {
    white-space: break-spaces;
}
.link-checkin {
    text-decoration: none !important;
    color: red !important;
    cursor: pointer;
}
.fix-height-scroll-checkinview {
    max-height: calc(100vh - 190px);
    /* height: calc(102vh - 130px); */
    overflow: auto;
}

/*Charmi(15-03-2023) Video/Canvas End*/

/*charmi(28-06-2023)*/
.pointer-event-none {
    pointer-events: none;
}
/*charmi(28-06-2023)*/

.overflow-hidden {
    overflow: hidden;
}

/* srushti 23-3-2023 areabutton for report */
.button-box {
    width: 200px;
    padding: 20px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border: none;
}

.one {
    background: lightcoral;
    color: #272829;
    border-radius: 5px;
}

/* tree */
.tree-tag-picker {
    background-color: var(--calendar-light);
    border-radius: 5px;
    min-height: 400px;
}

.tree-tag-control .tree-tag-label {
    font-size: 17px;
    font-weight: 700;
}

.tree-tag-control .tree-tag-box.tree-tag-templare-col-11 {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    grid-gap: 30px;
}

.tree-tag-control .tree-tag-box.tree-tag-templare-col-15 {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-gap: 30px;
}

.tree-tag-control .tree-label-box {
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(15, 1fr);
    grid-gap: 30px; */
}

/* .tree-tag-control .tree-tag-box .tree-tag-text { */
.tree-tag-text {
    cursor: pointer;
    /* background-color: var(--calendar-light); */
    background-color: var(--white);
    text-align: center;
    height: 42px;
    /* width: 84px; */
    width: auto;
    box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
    font-weight: 700;
    font-size: 17px;
    border-radius: 8px;
}

.tree-tag-control .tree-label-box .tree-label-text {
    cursor: pointer;
    /* background-color: var(--calendar-light); */
    background-color: var(--white);
    text-align: center;
    height: 40px;
    /* width: 84px; */
    box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
    font-weight: 700;
    font-size: 14px;
    border-radius: 1.5rem;
    margin-right: 12px;
}
/* heli_13/4/2023(7:22) */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

/* Bhautik 29-06-2023 12:47 PM ~ Start ~ For Loader (Ring) */
.btn-action-loader {
    transform-origin: center;
    animation: btn-action-loader-anim 0.75s step-end infinite;
}

@keyframes btn-action-loader-anim {
    8.3% {
        transform: rotate(30deg);
    }

    16.6% {
        transform: rotate(60deg);
    }

    25% {
        transform: rotate(90deg);
    }

    33.3% {
        transform: rotate(120deg);
    }

    41.6% {
        transform: rotate(150deg);
    }

    50% {
        transform: rotate(180deg);
    }

    58.3% {
        transform: rotate(210deg);
    }

    66.6% {
        transform: rotate(240deg);
    }

    75% {
        transform: rotate(270deg);
    }

    83.3% {
        transform: rotate(300deg);
    }

    91.6% {
        transform: rotate(330deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
/* Bhautik 29-06-2023 12:47 PM ~ End ~ For Loader (Ring) */

/* Bhautik 07-07-2023 11:45 AM ~ Start ~ For Loader (Ring) */
.v-video-container {
    position: relative;
    width: 100%;
    height: 330px;
    background: red;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.v-capture-layer {
    position: absolute;
    width: 75%;
    height: 55%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 400px;
    height: 200px; */
    background: rgba(10, 10, 10, 0.5);
    /* border: 1px solid yellow; */
}

.v-video-cap {
    /* position: absolute;
    top: 0;
    left: 0; */
    display: block;
    width: auto !important;
    height: auto !important;
    max-width: none !important;
    /* width: auto !important  ; */
}
/* Bhautik 07-07-2023 11:45 AM ~ End ~ For Loader (Ring) */

.main-selectpicker {
    position: relative;
}
.btn-redirect{
    color: #22317c !important;
}
.btn-redirect:hover {
    background-color: #22317c;
    color: #fff !important;
    -webkit-transition: opacity .15s linear;
}

.submit-btn-loding .btn-loader{
    top: 35px;
    right: 5px;
}

.redirect-btn {
    gap: 15px;
}

.redirect-btn .btn-redirect-map,
.redirect-btn .btn-redirect-phone {
    background-color: var(--darkblue);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.redirect-btn .btn-redirect-map i,
.redirect-btn .btn-redirect-phone i{
    font-size: 24px;
    color: var(--white);
    text-align: center;
}

.hotel-content {
    /* border: 1px solid var(--input-border); */
    background-color: #f7f7f7;
    padding: 15px;
    border-radius: 5px;
}
.text-danger {
    color: #ff0000;
}
.top-13{
    top: 13px !important;
}

.successfull-body .dayssuites-logo {
    position: absolute;
    top: 8px;
    z-index: 9999;
    height: 35px;
    display: block;
    width: 35px;
    left: 20px;
    overflow: visible;
    margin-bottom: 0px !important;
}
.successfull-body .dayssuites-logo img{
    width: 35px;
    border-radius: 50%;
    height: 35px;
    object-fit: fill;
    border: 1px solid var(--header-color);
    
}
.successfull-body .successfulcheck-img{
    height: 150px;
}
.position-relative{
    position: relative;
}
.successfull-body .back-btn-sec{
    text-align: center;
}

.hotel-content {
    margin-top: 15px;
}

/* .successfull-section .successfulcheck-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
} */

.select-document .card {
    margin-bottom: 15px !important;
}

/* .successfull-section .successfulcheck-img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.successfull-section .dayssuites-logo{
    height: 80px;
}
.successfull-section .card {
    width: 80px;
    height: 80px;
    border-radius: 50% !important;
} */
/* .successfull-section .select-document {
    display: flex;
    justify-content: center;
} */
/* .container .successfull-section .select-document .card .card-body{
    padding: 0px !important;
} */