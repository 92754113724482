:root{
    --lightblue: #008EFF;
    --btn-lightblue :#0180E5;
    --lightblue-100: #54A4FF;

    --darkblue: #003967;
    --darkblue-100: #0F3C97;
    --darkblue-200: #002A4D;
    --darkgray: #617181;
    --darkgray-100 : #565656;

    --lightgray: #c4cacf;
    --lightgray-100:#F5F5F5;
    --lightdarkgray: #f0f8ff;
    --lightgray-200: #BCBCBC;
    --lightgray-300: #9E9E9E;

    --white: #ffffff;
    --red: #FF0000;
    --black: #000000;
    --green:#089D49;
    --header-color: #E3EAF1;
    --warning: #FFB223;
    --green-100:#009F0A;

/* heli_13/3/2023 */
    --input-border: #E1E1E1;
    --input-border-hover: #c4cfdc;
    --transparent: #00000000;

    
    --btn-danger-transparent: #d22d3d1a;
}