@media (min-width: 480px) and (max-width: 575px) {
  .col-xs {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
  }

  .col-xs-auto {
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: none !important;
  }

  .col-xs-1 {
    -ms-flex: 0 0 8.33333% !important;
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }

  .col-xs-2 {
    -ms-flex: 0 0 16.66667% !important;
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }

  .col-xs-3 {
    -ms-flex: 0 0 25% !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-xs-4 {
    -ms-flex: 0 0 33.33333% !important;
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }

  .col-xs-5 {
    -ms-flex: 0 0 41.66667% !important;
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }

  .col-xs-6 {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xs-7 {
    -ms-flex: 0 0 58.33333% !important;
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }

  .col-xs-8 {
    -ms-flex: 0 0 66.66667% !important;
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }

  .col-xs-9 {
    -ms-flex: 0 0 75% !important;
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-xs-10 {
    -ms-flex: 0 0 83.33333% !important;
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }

  .col-xs-11 {
    -ms-flex: 0 0 91.66667% !important;
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }

  .col-xs-12 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 411px) and (max-width: 991px) {
  .successfull-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .hotel-content {
    margin-top: 30px;
  }
  .select-document .card {
    margin-bottom: 20px !important;
  }
  .successfull-body .successfulcheck-img {
    height: 200px;
  }
  .hotel-content {
    padding: 20px;
  }
  .successfull-section .text-18 {
    font-size: 20px !important;
  }
  .successfull-section .text-14 {
    font-size: 16px !important;
  }
  .successfull-section .text-16 {
    font-size: 18px;
  }
}

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
}

@media (max-width: 991px) {
  /* .document-image.scan-img img {
    max-width: 290px;
    padding: 0 0 400px 0;
} */
  .rs-picker-menu {
    max-width: calc(100vw - 10%);
  }
  .form-group {
    margin-bottom: 20px !important;
  }
  /* .days-suites-logo {
    margin: 0 0 10px 0 !important;
} */
  .location-icon img {
    height: 15px;
  }
  .card-body {
    padding: 1.25rem 2rem !important;
  }

  .select-document .card .card-body {
    padding: 12px !important;
  }
  .main-title {
    font-size: 21px;
  }
  .btn {
    font-size: 16px !important;
  }
  .selfverification-info {
    margin: 0 0 40px 0;
  }
  .label-form {
    font-size: 14px;
  }
  .fixed-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90% !important;
    margin: 0 auto;
    background-color: #fff;
  }
}
@media (max-width: 767px) {
  .rs-picker-toggle-value {
    line-height: 2.4;
  }
}
@media (max-width: 575px) {
  .r-hotelcharges-left {
    font-weight: 500 !important;
  }
  .r-hotelcharges-right {
    font-weight: 600 !important;
  }
  .fw-700 {
    font-weight: 700 !important;
  }
  .hotelcharges-desc.desc-data {
    font-size: 11px;
    line-height: 1.7;
  }
}

@media (max-width: 480px) {
  .set-padding-doc {
    padding: 15px 0;
    margin: 20px 0;
  }
  .id-verification-sec .document-sacn {
    margin-bottom: 30px;
  }
  .rs-picker-toggle-textbox::placeholder,
  .rs-picker-toggle-textbox,
  .rs-picker-toggle-value,
  .intl-tel-input.allow-dropdown .selected-flag {
    font-size: 14px;
  }
  .reservation-detail-title .sec-title {
    font-size: 13px;
  }
  .successfulcheckin-desc,
  .sec-title {
    font-size: 17px;
  }
  input.form-control,
  textarea.form-control,
  .rs-calendar-header-title .rs-picker-select-menu-item,
  .reservation-details,
  .location span,
  .checkbox,
  .radio,
  .readyto-check-desc {
    font-size: 13px;
  }
  .reservation-sec .reservation-list li,
  .checkin-app-form .checkbox,
  .standardcheckin-title,
  .detailsabout-desc,
  .reservation-list li,
  .reservation-sec {
    font-size: 12px;
  }
  .standardcheckin-title p {
    font-size: 16px !important;
  }
  .review-hotelcharges span,
  .validunexpired-sec,
  .issuedid-sec,
  .inn-suites-desc {
    font-size: 14px;
  }
  .termsconditions-desc,
  .stay-apply,
  .desc-data {
    font-size: 13px;
  }
  .photoyourself-desc,
  .idverification-oneline {
    font-size: 16px;
    margin: 0 0 15px 0;
  }
  .electronicsign-desc {
    font-size: 9px;
  }
  .main-title {
    font-size: 18px;
  }
  .terms-conditions-desc,
  .terms-conditions-title .main-title {
    font-size: 11px;
  }

  .selfverification-info {
    font-size: 13px;
    margin: 0 0 15px 0;
  }

  .self-verification-inner {
    margin: 20px 0;
    padding: 20px 0;
  }

  .time-info {
    font-size: 18px;
  }
  .creditard-desc,
  .stay-information-info .desc-data {
    font-size: 13px;
  }

  .label-form {
    font-size: 12px;
  }
  .terms-conditions-desc {
    font-weight: 500;
  }
  .checkbox .checkmark,
  .radio .checkmark {
    top: 2px;
    height: 16px;
    width: 16px;
  }
  .checkbox,
  .radio {
    padding-left: 22px !important;
  }
  /* .days-suites-logo .document-image img {
    max-width: 200px;
    padding: 0 0 115px 0;
}
.days-suites-logo {
    margin: 0 0 20px 0 !important;
} */
  .scan-card img {
    height: 16px;
  }
  ul.plusminus-list {
    margin-top: 3px;
  }
  .btn.plus-minus-icon {
    line-height: 25px !important;
    font-size: 20px !important;
  }
  .input-group-text-right {
    right: 0px; /*-10px => charmi(27-02-2023)*/
  }
  /* .readyto-check-sec .logo img{
    width: 205px;
}charmi */
  /* .readyto-check-sec .logo{
    min-width: 215px;
    margin: 0 0 20px 0;
}charmi */
  .rs-calendar-table-cell {
    padding: 2px;
  }
  .rs-calendar {
    min-height: 295px;
  }
  .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    font-size: 12px;
  }
  .rs-calendar-time-dropdown-cell {
    font-size: 12px;
  }
  .btn {
    padding: 15px 0 !important;
  }
  .btn-sm-txt {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }
  /* .document-image img {
    width: 50%;
    height: 0;
    max-width: 50px;
    padding: 0 0 50px 0;
} */
  .id-verification-sec .select-document .card {
    margin: 0;
    width: 118px;
    height: 113px;
    align-items: center;
    flex-direction: inherit;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .select-document .card .card-body span {
    font-size: 12px;
    font-weight: 600;
    margin-top: 15px;
    display: block;
  }
  .idverification-imgs {
    height: 45px;
    margin-bottom: 5px;
  }
  .checkbox .checkmark:after,
  .radio .checkmark:after {
    height: 8px;
  }

  .visacard-img {
    height: 180px;
  }

  .electronicsign-title {
    font-weight: 500;
    font-size: 14px;
  }
  .electronicsign-desc {
    font-size: 10px;
  }
}

@media (max-width: 375px) {
  .welcome-title h1 {
    font-size: 26px;
    margin-top: 20px;
  }
  .welcome-img-logo {
    height: 80px;
    margin: 20px 0;
  }
  .welcome-text {
    font-size: 16px;
    margin: 0 0;
  }
  .welcome-img {
    margin: 30px 0 40px;
    height: 210px;
  }
}

@media (max-height: 740px) and (min-height: 700px) {
  .welcome-title h1 {
    font-size: 32px;
    margin-top: 30px;
  }
  .welcome-img-logo {
    height: 90px;
    margin: 30px 0;
  }
  .welcome-text {
    font-size: 18px;
    margin: 20px 0;
  }
  .welcome-img {
    margin: 40px 0 40px;
    height: 230px;
  }
}
