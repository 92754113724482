.app{
    min-height: 100vh;
    position: relative;
    background-color: lightblue;
  }
  .camera{
    position: relative;
  }
  .result{
    position: fixed;
    top:0;
    /* left: 100%; */
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
  }
  .result >.hasphoto{
    left: 0;
    border: 1px solid red;
  }
  
  video{
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
 